import React, { Fragment, useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Register from '../Pages/Register/Register';
import EmailSended from '../Pages/EmailSended/EmailSended';
import Verification from '../Pages/Verification/Verification';
import { useDispatch } from 'react-redux';
import { getUser } from '../Redux/Features/userSlice';
import { getCookie } from '../utils/helper';
import Home from '../Pages/Home/Home';
import GoogleAuth from '../Pages/GoogleAuth/GoogleAuth';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Login from '../Pages/Login/Login';
import ForgotPasswordRequest from '../Pages/ForgotPasswordRequest/ForgotPasswordRequest';
import SetNewPassword from '../Pages/SetNewPassword/SetNewPassword';
import AllProfiles from '../Pages/LinkedinProfiles/AllProfiles/AllProfiles';
import Profile from '../Pages/LinkedinProfiles/Profile/Profile';
import { getLinkedinProfiles } from '../Redux/Features/getLinkedinProfilesSlice';
import AllCampaigns from '../Pages/Campaigns/AllCampaigns/AllCampaigns';
import Campaign from '../Pages/Campaigns/Campaign/Campaign';
import CreateCampaign from '../Pages/Campaigns/CreateCampaign/CreateCampaign';
import { getProducts } from '../Redux/Features/getProductsSlice';
import { getCampaignsSummary } from '../Redux/Features/getCampaignsSummarySlice';

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let token = getCookie('token');
    dispatch(getUser(token));
    if (token) {
      dispatch(getLinkedinProfiles());
      dispatch(getProducts());
      dispatch(getCampaignsSummary());
    }
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={'/'} element={<PrivateRoute element={<Home />} />} />
        <Route path={'/linkedin-profiles'} element={<PrivateRoute element={<AllProfiles />} />} />
        <Route path={'/linkedin-profiles/profile/:id'} element={<PrivateRoute element={<Profile />} />} />
        <Route path={'/campaigns'} element={<PrivateRoute element={<AllCampaigns />} />} />
        <Route path={'/create-campaign'} element={<PrivateRoute element={<CreateCampaign />} />} />
        <Route path={'/campaign/:id'} element={<PrivateRoute element={<Campaign />} />} />
        <Route path={'/register'} element={<PublicRoute element={<Register />} />} />
        <Route path={'/login'} element={<PublicRoute element={<Login />} />} />
        <Route path={'/forgot-password-request'} element={<PublicRoute element={<ForgotPasswordRequest />} />} />
        <Route path={'/set-password/:code'} element={<SetNewPassword />} />
        <Route path={'/email-sent'} element={<PublicRoute element={<EmailSended />} />} />
        <Route path={'/verification/:code'} element={<PublicRoute element={<Verification />} />} />
        <Route path={'/google'} element={<PublicRoute element={<GoogleAuth />} />} />
      </>
    )
  );

  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default AppRoutes;
